import "../sass/7-bundles/index.scss";


async function loadPreloader() {
  import('../scripts/preloader.js')
}

async function loadExperience() {
  import('../experience/experience.js')
}

async function loadIndexAnim() {
  import('../scripts/indexAnim.js')
}

window.addEventListener('load', async function loadModules() {
  await loadPreloader()
  await loadExperience()
  await loadIndexAnim()
})
